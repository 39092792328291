
.pair-info {
    margin-top: 10px;
    .compare-button {
        padding-top: 4px;
    }
    .compare-results {
        background-color: #90F3FF ;
        border: 2px solid black;
        border-radius: 5px;

        margin-bottom: 20px;
        padding-bottom: 5px;
    }
    .results-entry {
        margin: 10px 0;
    }
    .results-row {
        &> div {
            padding: 0 5px;
        }
        display: flex;
        justify-content: space-between;
    }
}