body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #add8e6;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

button {
  background: #68edc6;
  border: 2px solid #68edc6;
  border-radius: 5px;
  padding: 4px 9px;
}

.App {
  text-align: center;
  background-color: #add8e6;
  max-width: 500px;
  margin: 20px auto;
}

.paystub-upload {
  border: 2px solid #000;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.paystub-upload textarea {
  height: 100px;
  margin-bottom: 10px;
}

.paystub-upload button {
  margin-bottom: 10px;
}

.paystub-upload .header {
  padding: 10px 0;
}

.balance-account {
  max-width: 100%;
  margin: 10px auto;
  padding-bottom: 5px;
}

.balance-account .balance-results {
  background-color: #90f3ff;
  border: 2px solid #000;
  margin: 10px auto 0;
  padding: 5px 30px;
}

.pair-info {
  margin-top: 10px;
}

.pair-info .compare-button {
  padding-top: 4px;
}

.pair-info .compare-results {
  background-color: #90f3ff;
  border: 2px solid #000;
  border-radius: 5px;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.pair-info .results-entry {
  margin: 10px 0;
}

.pair-info .results-row {
  justify-content: space-between;
  display: flex;
}

.pair-info .results-row > div {
  padding: 0 5px;
}

.accounts-list {
  border: 2px solid #000;
}

.accounts-list header {
  padding: 10px 0;
}

.accounts-list .account-pair {
  border-top: 2px solid #000;
  padding-top: 10px;
}

.accounts-list .pair-body {
  display: flex;
}

/*# sourceMappingURL=index.99144f1f.css.map */
