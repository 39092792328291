.balance-account {
    max-width: 100%;
    margin: 10px auto;
    padding-bottom: 5px;

    .balance-results {
        margin: 10px auto 0px;
        padding: 5px 30px;
        border: 2px solid black;
        background-color: #90F3FF;
    }
}
