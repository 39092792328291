.accounts-list {
    header {
        padding: 10px 0px;
    }
    border: 2px solid black;
    .account-pair {
        border-top: 2px solid black;
        padding-top: 10px;
    }
    .pair-body {
        display: flex;
    }
}