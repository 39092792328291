.paystub-upload {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    
    border: 2px solid black;
    margin-top: 20px;
    margin-bottom: 20px;

    textarea {
        height: 100px;
        margin-bottom: 10px;
    }

    button {
        margin-bottom: 10px;
    }

    .header {
        padding: 10px 0px;
    }
}